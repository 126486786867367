import React, { useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function po_terms() {
    return (
        <>
            <IndexNavbar fixed />
            <section className="  bg-no-repeat header relative justify-center items-center md:pt-24 pt-24 let heigt" >
            </section>
            <div className="container mx-auto md:px-16 px-4 md:pb-10">
                <div>
                    <h1 className="text-black font-bold md:text-4xl text-2xl text-center md:pt-16">
                        Purchase Order - Terms & Conditions
                    </h1>
                </div>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-16 pt-6">
                    GENERAL:
                </h1>
                <p className="text-sm md:pt-4 pt-4 " style={{ textAlign: "justify" }}>
                    These general terms and conditions of purchase under this Purchase Order (“P.O”) by Bizcovery Private Limited are mandatory and binding on the Supplier for sale and purchase of finished products /services (“Product or Services”).
                    The terms and conditions contained herein supersede the terms and conditions offered by Supplier along with their proposal/ offer, the Standard terms and conditions shall also supersede the terms of the invoice raised by either Party for the supply of products/services.
                    The terms of the PO shall be considered as the complete contract in respect of the transaction between Bizcovery Private Limited and the Supplier.
                    Any changes to the terms of the P.O shall be made in writing and shall become binding only upon its acceptance by both Parties in writing.
                </p>
                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    PRICE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    The price mentioned in this P.O. is final and binding. Price increases or charges not expressly set out in the P.O shall not be effective unless agreed to in advance in writing by Bizcovery.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    PAYMENT:
                </h1>

                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    (a) Supplier shall raise an invoice on Bizcovery Private Limited as per the payment terms mentioned in this PO.
                    Bizcovery Private Limited shall make payment of the invoice subject to the fulfilment of the following conditions:
                </p>
                <ul className="list-disc pl-10 text-sm" style={{ textAlign: "justify" }}>
                    <li>
                        Upon issuance of the GRN and quality clearance from Bizcovery Private Limited for the goods delivered and
                        received at the delivery location provided in this PO; and
                    </li>
                    <li>Subject to the fulfilment of the terms and conditions under this PO.</li>
                </ul>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (b) Bizcovery Private Limited shall make advance payments under this PO subject to mutual agreement between
                    the parties, and the advance payment shall be adjusted in the full and final payments towards the goods.
                </p>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (c) If the GRN from Bizcovery Private Limited is delayed due to any reason and the Supplier has requested for
                    advance payments for the goods supplied, then Bizcovery Private Limited shall make advance payment subject
                    to the fulfilment of the following conditions:
                </p>
                <ul className="list-disc pl-10 text-sm" style={{ textAlign: "justify" }}>
                    <li>
                        Weighbridge receipt or any such other documents confirming the quantities and quality of the goods received.
                    </li>
                    <li>
                        Subject to the fulfilment of the terms and conditions including but not limited to credit period,
                        delivery dates, etc. under this PO.
                    </li>
                </ul>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (d) In case of advance payments based on milestones, Bizcovery Private Limited shall reserve the right to
                    withhold <span className="font-semibold">__%</span> of payment at its sole discretion until the milestones are achieved.
                    After the issuance of the GRN, Bizcovery Private Limited shall pay the balance amount.
                </p>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (e) If the payment due date falls on a bank holiday or a public holiday, the payment date shall be considered
                    as the next business day immediately following such payment due date.
                </p>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (f) In the event of any default in the supply of the commodity under this PO, the Supplier shall refund the
                    Advance along with interest at the rate of <span className="font-semibold">24%</span> per annum.
                </p>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (g) The price mentioned in the PO is inclusive of all cess, taxes, and any other statutory charges applicable
                    for transactions of this nature.
                </p>

                <p className="text-sm pt-2" style={{ textAlign: "justify" }}>
                    (h) TDS u/s 194Q is applicable for all purchases exceeding INR 50 Lakhs of the commodity value in any financial year.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    GOODS AND SERVICES TAX & OTHER TAXES:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    GST shall be paid at actual, against documentary evidence.
                    Bizcovery Private Limited may withhold from all amounts payable to Supplier all applicable withholding taxes and to remit those taxes to the applicable governmental authorities as required by applicable laws.
                    The Supplier or the third-party assignee shall be liable for all tax payments as required under applicable law and for statutory filings and compliances.
                    Failure to make tax payments resulting in Bizcovery Private Limited's inability to claim tax credits, Supplier shall be liable to indemnify Bizcovery Private Limited and pay such amounts, penalties, interests and/or any other sums accruing to Bizcovery Private Limited due to such non-compliance and make equivalent payments to Bizcovery Private Limited.
                    Any default by the Supplier or a third-party assignee shall be deemed as a default of the Supplier and may result in the cancellation of the P.O and all advance payment received, without any deduction shall be refunded by the Supplier.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    ACCEPTANCE OF P.O:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    The P.O. and the terms herein shall be deemed to be accepted by the Supplier upon the receipt of the P.O. unless expressly rejected by Supplier in writing.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    ADVANCE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Advance, if any paid by Bizcovery Private Limited in accordance with this P.O shall be adjusted against invoice raised by Supplier for payment for Product or Services.
                    The Supplier shall at all times procure high quality raw material and advance shall be payable strictly against receipt of raw materials.
                    Advances shall be disbursed within any date agreed between the Parties.
                    Advance shall be paid directly to the Supplier.
                    Notwithstanding anything contained herein, Supplier acknowledges that all Advances paid to the Supplier shall be deemed payments made to Supplier under this P.O and Supplier shall be liable for supply of Product or return of Advance received by it.
                    In case Bizcovery Private Limited issues advance payments to the Supplier, the Supplier shall simultaneously, with the issue of an advance payment, issue a written confirmation of receipt of payment by the Supplier.
                    Interest on Advance, unless waived shall be payable by the Supplier in accordance with terms of the P.O. and shall be stated in the P.O.
                    The Advance shall be utilized solely in connection with this P.O. and cannot be adjusted, set off or utilized in any manner other than in connection with this P.O unless expressly agreed by Bizcovery Private Limited.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    SECURITY:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier shall, unless otherwise waived by Bizcovery Private Limited provide securities in the form of PDC’s, against the Advance paid by Bizcovery Private Limited.
                    Bizcovery Private Limited shall be entitled to enforce the securities in case of any default without any notice to Supplier.
                </p>
                <p className="text-sm md:pt-4">
                    (i) Undated cheque (UDC), if required, shall be submitted for payment of Advance by Bizcovery Private Limited.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    STANDARD COMPLIANCE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    The Product/Services provided shall be strictly in line with the specifications and guidelines and other documents provided along with P.O./enquiry.
                    Wherever needed, the applicable and relevant industry standards shall be followed.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    DISPATCH SCHEDULE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier shall send us a dispatch schedule to match the delivery schedule of our project department within 7 days of this P.O.
                    In case, the supplier fails to abide by the agreed manufacturing schedule at any stage, Bizcovery Private Limited has the right to cancel the entire / part P.O. on you, without any cost implication on us and get the work done by a suitable supplier of our choice.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    DELIVERY:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Delivery basis shall be as per PO. If Transportation is in Supplier’s scope, material shall be delivered at Bizcovery Private Limited or it’s client’s address and duly accepted by the QC/ Stores.
                    Time is of the essence with respect to delivery of the Product. Product shall be delivered and services performed by the applicable Delivery Schedule.
                    Supplier must immediately notify Bizcovery Private Limited if Supplier is likely to be unable to meet a delivery date under the Delivery Schedule.
                    At any time prior to the date agreed in the Delivery Schedule, Bizcovery Private Limited may, upon notice to Supplier, and in its sole discretion, cancel or change a P.O, or any portion thereof, for any reason, including, without limitation, for the convenience of Bizcovery Private Limited or due to failure of Supplier to comply with this Agreement, unless otherwise noted.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    LIQUIDATED DAMAGES / PRICE REDUCTION FOR DELAY IN DELIVERY:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    In the event Supplier fails to deliver the products/services within the contracted delivery date in accordance with the Delivery Schedule, the price payable in accordance with the P.O. will be reduced at the rate of 1% (one percent) of the total value of P.O per week up to a maximum of 10% (ten percent) of the total value of the PO.
                    If the Supplier fails to supply the finished goods no later than 60 days from the Delivery Schedule, Bizcovery Private Limited shall be entitled to cancel the PO and in the event of such cancellation the Supplier shall be liable to pay (a) liquidated damages of 10% of the unfulfillment quantity; (b) differential cost incurred in procuring Product from third parties; (c) Advances with interest, if any.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    PAYMENT TERMS:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier will issue all invoices on a timely basis. All invoices delivered by Supplier must meet Bizcovery Private Limited ’s requirements.
                    Bizcovery Private Limited will, subject to adjustments on account of Advance, or other deductions, pay the undisputed portion of properly rendered invoices within ninety (90) days from the invoice date issued by Supplier.
                    Bizcovery Private Limited shall have the right to withhold payment of any invoiced amounts that are disputed in good faith until the parties reach an agreement with respect to such disputed amounts.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    INSPECTION:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    All delivery of Products and performance of services shall be subject to Bizcovery Private Limited’s right of inspection.
                    Inspection may be carried out by Bizcovery Private Limited / it’s client / appointed inspection agency after submission of materials test certificates for all the materials as per QAP along with all the Internal inspection report.
                    Upon inspection, Bizcovery Private Limited, or its client shall either accept the Products or Services or reject them. Bizcovery Private Limited or its client shall have the right to reject any Product that are delivered in excess of the quantity ordered or are damaged or defective.
                    In addition, Bizcovery Private Limited shall have the right to reject any Product or Services that are not in conformance with the Specifications stated in the P.O. However, any of such inspection does not absolve the Supplier of its responsibility in case of any defects/issues arising out of materials workmanship / quality of materials, etc.
                    Rectification work, if any, shall be carried out within a week from the date of identification of the defect, at Supplier’s cost. Supplier will give Bizcovery Private Limited 7 working days or as specified by Customer, advance notice for inspection of raw materials / finished goods.
                    If Bizcovery Private Limited or its client reject Products or Services such rejection shall be at Supplier’s expense and risk of loss and Bizcovery Private Limited’s shall at its option, either: (i) demand full credit or refund of all Advance paid by Bizcovery Private Limited to Supplier for the rejected Product or Service; or (ii) or direct the Supplier to replace the Product to be received within the time period specified by Bizcovery Private Limited.
                    Supplier shall not deliver Products or Services that were previously rejected on grounds of non-compliance with the P.O, unless delivery of such Products is approved in advance by Bizcovery Private Limited or its client.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    DISPATCH:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Dispatch instructions will be sent to Supplier separately. Supplier shall dispatch the materials only after getting inspection clearance as well as dispatch clearance from us / our customer end.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    DISPATCH DOCUMENTS:
                </h1>
                <p className="text-sm md:pt-4" style={{ textAlign: "justify" }}>
                    The Product shall be dispatched and the following documents shall be submitted to Bizcovery Private Limited on dispatch:
                </p>
                <p className="text-sm pt-2 pl-6">
                    (i) Tax Invoice with GST for payment.
                    <br /> (ii) Weighing slip
                    <br /> (iii) Lorry Receipt
                    <br /> (iv) Inspection report, if applicable
                    <br /> (v) Any other documents specifically required by Bizcovery Private Limited.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    TRANSPORTATION:
                </h1>
                <p className="text-sm md:pt-4 pt-4">
                    The approved Product shall be dispatched as per dispatch instructions only upon the receipt of the clear Inspection report along with dispatch clearance.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    WARRANTY:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    The Product supplied by Supplier shall be covered under warranty against bad workmanship, under performance and Poor quality of materials for a period of 24 months from the date of receipt of the Product or Services.
                    In the event the material does not meet the P.O requirements, Supplier shall rectify and replace at his cost. Supplier warrants to Buyer that during the Product or Service Warranty Period, all Products or Services provided in accordance with the P.O. shall be:
                </p>
                <p className="text-sm pt-2 pl-6">
                    (i) of merchantable quality;
                    <br /> (ii) fit for the purposes intended;
                    <br /> (iii) unless otherwise agreed to by Bizcovery Private Limited;
                    <br /> (iv) free from defects in design, material, and workmanship;
                    <br /> (v) in strict compliance with the specifications under the P.O;
                    <br /> (vi) free from any liens or encumbrances on title whatsoever;
                    <br /> (vii) in conformance with any samples provided to Bizcovery Private Limited;
                    <br /> (viii) compliant with all applicable federal, provincial, and municipal laws, regulations, standards, and codes.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    FORCE MAJEURE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Force-majeure will be applicable only in the event of rare natural calamity and Acts of God, such as an earthquake or flood, tsunami, which causes enough havoc to disturb the production and supply schedules.
                    The normal conditions of Strikes, Lock-outs, Changes in Govt. Regulations, problems arising out of statutory requirements including but not limited to Code requirements, shortage of electrical power etc. are not covered under force-majeure conditions.
                    If the force majeure event continues for a period greater than 90 days, the Parties are at liberty to rework the terms of the supply by raising and accepting a new Purchase Order
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    PACKING AND MARKING:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Irrespective of what is specified in various documents, all consignments shall be suitably packed to withstand rigours of road transport / handling and storage at site.
                    In case of Export contracts, suitable sea worthy packing, crating for transport shall be done.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    CONTRADICTIONS:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    In the event Supplier notices any contradiction in various documents, Supplier shall bring the same immediately to the notice of Bizcovery Private Limited for a resolution, within a week of receipt of PO.
                    Any claim made later shall not be entertained.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    CANCELLATION OF P.O:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Without prejudice to any other rights or remedies of Bizcovery Private Limited, Bizcovery Private Limited shall be entitled to terminate this P.O forthwith upon a breach of terms of the P.O by Supplier.
                    Termination of this P.O for breach by Supplier shall not discharge Supplier’s obligations under any and all other purchase orders issued by Bizcovery Private Limited to Supplier, or the Supplier’s obligations under this P.O. including refund of advance payments and surviving provisions (confidentiality indemnity, taxes, liquidated damages, governing laws and dispute resolution.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    LIABILITY OF SUPPLIER:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier shall be liable for all losses resulting to Bizcovery Private Limited from non-compliance with the Delivery Schedule or breach of any terms of the P.O., including any loss of profits, to the maximum extent permissible under law.
                    Notwithstanding anything, no limitation or exclusion of liability shall apply with respect to any claims based on this P.O arising out of the Supplier’s wilful misconduct or gross negligence, or with respect to any claims for personal injury or property damage, or to Supplier’s indemnification obligations stated herein.
                    The Supplier also hereby agrees that whatever Supply made by them on behalf of Bizcovery Private Limited to their customer in the event of any delayed payment from the end-customer of Bizcovery Private Limited, Bizcovery Private Limited is entitled to claim delay charges from the Supplier
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    CONSEQUENTIAL DAMAGES:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Bizcovery Private Limited shall in no event be liable for loss of profit, loss of revenues, loss of use, loss of production, costs of capital or costs connected with interruption of operation, loss of anticipated savings or for any special, indirect or consequential damage or loss of any nature whatsoever, accruing to Supplier arising out of execution of this PO or cancellation of the PO due to non-performance.
                    In any event, Bizcovery Private Limited’s entire liability for any claim, whether in contract, tort, or any other theory of liability shall be limited to the P.O value.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    INSURANCE:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Bizcovery Private Limited shall not be responsible for procuring insurance for and on behalf of the Vendor of the products.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    ASSIGNMENT:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier may not assign or subcontract the P.O placed under this P.O in whole or in part, without Bizcovery Private Limited’s prior written consent.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    CONFIDENTIALITY:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    Supplier undertakes that it shall, at all times, maintain confidentiality of all the information including but not limited to drawings and other technical specifications received by it in respect of the P.O and/or disclosed to it by Bizcovery Private Limited or its client and shall not disclose or divulge the same or any part thereof to any third party without the prior written consent of Bizcovery Private Limited.
                    The obligations of this clause shall survive termination of this P.O. regardless of the reasons for termination of this P.O.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    GOVERNING LAW & DISPUTE RESOLUTION:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    This P.O shall be governed by the laws of Karnataka, India and any dispute arising out of this order will be submitted to Indian Law and to the exclusive jurisdiction of courts of Bengaluru Urban.
                </p>

                <h1 className="text-green-100 font-semibold md:text-2xl text-xl  md:pt-6">
                    COSTS AND EXPENSES:
                </h1>
                <p className="text-sm md:pt-4 pt-4" style={{ textAlign: "justify" }}>
                    All costs and expenses incurred in connection with P.O. including costs incurred in recovering the advance payments shall be to the Supplier’s account.
                </p>

            </div>
            <Footer />
        </>
    );
}