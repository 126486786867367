import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import Home from "views/Home.js";
import Contact from "components/Contact.js";
import About from "components/About.js";
import Testimonials from "components/Testimonials.js";
import Privacy from "components/Privacy.js";
import Refunds from "components/Refunds.js";
import Terms from "components/Terms.js";
import Products from "components/Products.js";
import Shop from "components/Shop.js";
import Product from "components/Product.js";
import Redonion from "components/Redonion.js";
import Pinkonion from "components/Pinkonion.js";
import Garlic from "components/Garlic.js";
import Spices from "components/Spices.js";
import Ginger from "components/Ginger.js";
import Vegetables from "components/Vegetables.js";
import Leafy from "components/Leafy.js";
import Fruits from "components/Fruits.js";
import Herbal from "components/Herbal.js";
import Oils from "components/Oils.js";
import Press from "components/Press.js";
import Career from "components/Career.js";
import Form from "components/Form.js";
import Forms from "components/Forms.js";
import "@reach/menu-button/styles.css";
import "@reach/combobox/styles.css";
import AccountDeletion from "components/AccountDeletion.js";
import po_terms from "components/po_terms.js";

// Custom component to handle redirect to static HTML
const StaticPageRedirect = ({ to }) => {
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/" exact component={Index} />
      <Route path="/contact" exact component={Contact}/>
      <Route path="/about" exact component={About}/>
      <Route path="/testimonials" exact component={Testimonials}/>
      <Route path= "/privacy" exact component ={Privacy}/>
      <Route path="/terms" exact component={Terms}/>
      <Route path="/home" exact component={Index}/>
      <Route path="/Press" exact component ={Press}/>
      <Route path="/career" exact component ={Career}/>
      <Route path="/form" exact component ={Form}/>
      <Route path="/forms" exact component ={Forms}/>
      <Route path="/account-deletion" exact component={AccountDeletion}/>
      <Route path="/po_terms" exact component={po_terms}/>
      
      {/* New route for landing pages */}
      <Route path="/pharmaceutical" render={() => <StaticPageRedirect to="/pharmaceutical.html" />} />
     
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
